"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
const [host, port] = document.location.host.split(':');
exports.config = {
    debug: false,
    // channels: `wss://${host}/ws/`,
    // couchdb: `https://${host}/cdb`,
    // cdn: "/static/dist/",
    // skipCount: 300,
    // skipTimeout: 3, //seconds
};
